<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('LauncherPlatforms')"
    @cancel="onCancel('LauncherPlatforms')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'LauncherPlatformCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'LauncherPlatforms',
      pageHeader: 'Создание настройки платформ',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Настройка платформ',
          route: { name: 'LauncherPlatforms' },
        },
        {
          text: 'Создание настройки платформ',
        },
      ],
      initialForm: {
        name: null,
        application: null,
        argumentTemplate: null,
        isConfig: null,
      },
      form: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Имя',
            },
            {
              type: 'text',
              key: 'application',
              label: 'Приложение',
            },
            {
              type: 'text',
              key: 'argumentTemplate',
              label: 'Аргументы',
            },
            {
              type: 'checkbox',
              key: 'isConfig',
              label: 'Конфигуратор',
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
    }),
  },
};
</script>
